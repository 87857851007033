@import "./_components.css";
@import "./_custom.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    line-height: 100%;
  }
  [hidden] {
    display: none !important;
  }
  h1,
  .h1 {
    font-size: 21.75px;
    line-height: 26px;
    font-weight: 600;
  }
  h2,
  .h2 {
    font-size: 19.5px;
    line-height: 24px;
    font-weight: 600;
  }
  h3,
  .h3 {
    font-size: 17.55px;
    line-height: 21px;
    font-weight: 600;
  }
  h4,
  .h4 {
    font-size: 16.25px;
    line-height: 20px;
    font-weight: 600;
  }
  h5,
  .h5 {
    font-size: 14.95px;
    line-height: 18px;
    font-weight: 600;
  }
  h6,
  .h6 {
    font-size: 13.95px;
    line-height: 17px;
    font-weight: 600;
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    @apply text-gray-800;
  }
  hr {
    width: 100%;
    height: 1px;
    border: none;
    opacity: 1;
  }
  input,
  textarea {
    border: none;
    background-color: transparent;
    @apply focus:outline-none;
  }
}
.btn {
  font-size: 14px;
  line-height: 23px;
  padding: 8px 24px;
  @apply inline-block rounded border text-center align-middle font-semibold leading-6 no-underline transition-[opacity,color,background-color,border-color,transform] active:scale-95 active:opacity-80;
}
.btn-primary {
  @apply border-primary bg-primary text-white hover:border-primary-active hover:bg-primary-active;
}
.btn-light-success {
  @apply border-success-light bg-success-light text-success  hover:bg-success hover:text-white;
}
.btn-light-primary {
  @apply bg-primary-light text-primary hover:bg-primary hover:text-white;
}
.btn-white-text-primary {
  @apply border-white bg-white text-primary hover:border-white-active hover:bg-white-active;
}
.btn-light-text-primary {
  @apply border-gray-200 bg-gray-200 text-primary hover:border-white-active hover:bg-white-active;
}
.btn-danger {
  @apply border-danger bg-danger text-white hover:border-danger-active hover:bg-danger-active;
}
.btn-light-danger {
  @apply border-danger-light bg-danger-light text-danger hover:bg-danger hover:text-white;
}
.btn-white-text-danger {
  @apply border-white bg-white text-danger hover:border-white-active hover:bg-white-active;
}
.btn-white-text-gray {
  @apply border-white bg-white text-gray-400;
}
.btn-light-text-danger {
  @apply border-gray-200 bg-gray-200 text-danger hover:border-white-active hover:bg-white-active;
}

.btn-light-warning {
  @apply border-warning-light bg-warning-light text-warning hover:border-warning-light hover:bg-warning hover:text-white;
}
.btn-warning {
  @apply border-warning bg-warning text-white hover:border-warning-active hover:bg-warning-active;
}
.btn-outline-primary {
  @apply border-primary bg-transparent text-primary hover:border-primary-active hover:bg-primary-active hover:text-white;
}
.btn-light {
  @apply border-gray-200 bg-gray-200 text-gray-500 hover:border-gray-300 hover:bg-gray-300;
}
.btn-white {
  @apply border-white bg-white text-gray-400 hover:border-white-active hover:bg-white-active;
}
.btn-link {
  @apply text-primary underline;
}
.btn-sm {
  font-size: 12px;
  line-height: 14px;
  padding: 10px 16px;
  height: 38px;
}
.btn-base {
  height: 44px;
}
.btn-lg {
  height: 44px;
}

aside::-webkit-scrollbar {
  width: 4px;
  height: 0px;
  --scrollbar-space: 0.4rem;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  --scrollbar-space: 0.4rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.advanced-cropper-preview__content {
  border-radius: 8px;
}

.advanced-cropper__background-wrapper {
  background: #f5f8fa;
}

.ql-container {
  height: unset;
}
.ql-container p {
  color: unset;
}
.ql-toolbar.ql-snow {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.ql-container.ql-snow {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.react-datepicker__day--disabled {
  color: #b0b0b0 !important;
}

.form-control__suggestions > ul > li {
  @apply cursor-pointer rounded-lg !p-2;
}
.form-control__suggestions > ul {
  @apply max-h-80 overflow-scroll;
}
.form-control__suggestions > ul > li:hover {
  @apply bg-primary-light text-primary;
}
.form-control__suggestions {
  @apply !top-5 !min-w-52 rounded-xl border border-gray-200 bg-white !p-1;
}
.form-control__input {
  @apply py-[14px];
}
input[type="radio"] {
  @apply accent-primary;
}

.flex-between {
  @apply flex items-center justify-between;
}
