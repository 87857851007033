input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
* {
  font-family: "Inter", sans-serif;
  -webkit-tap-highlight-color: transparent;
  @apply selection:bg-success selection:text-white;
}
html {
  font-size: 16px;
  line-height: 1;
}
body {
  line-height: 1.5;
  @apply bg-light text-dark;
}
button {
  white-space: nowrap;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
input[type="checkbox"] {
  width: 1.25rem;
  border-radius: 0.25rem;
  aspect-ratio: 1/1;
}
input[type="color"]::-webkit-color-swatch {
  @apply rounded-md;
}
p {
  @apply text-gray-500;
}
fieldset {
  @apply border-0 border-t border-gray-200;
}
svg {
  outline: none;
  @apply [&_*]:outline-none;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex > *,
.flex-center > * {
  min-width: 0;
  min-height: 0;
}
.recharts-responsive-container {
  width: 100% !important;
  overflow: auto;
}
.recharts-wrapper {
  max-width: 100%;
}
.recharts-curve.recharts-area-area {
  pointer-events: none;
}
body:has(.modal.active, .drawer.active) {
  overflow: hidden;
}
.recharts-responsive-container {
  overflow: hidden;
}
